<template>
  <i class="bi  mobile-nav-toggle d-xl-none"
     :class="{'bi-list': !toggle,'bi-x': toggle}"
      @click="navbar"></i>

  <!-- ======= Header ======= -->
  <header id="header">
    <div class="d-flex flex-column">

      <div class="profile">
        <img alt="" class="img-fluid rounded-circle" src="@/assets/img/profile-img.jpg">
        <h1 class="text-light"><a href="index.html">Matteo O. Bovero</a></h1>
        <div class="social-links mt-3 text-center">
            <a class="linkedin" href="https://www.linkedin.com/in/mobovero/"
               target="_blank"><i class="bx bxl-linkedin"></i></a>

          <a class="github" href="https://github.com/matte78" target="_blank"><i class="bx bxl-github"></i></a>
          <a class="flickr" href="https://www.flickr.com/photos/bovero/"
             target="_blank"><i class="bx bxl-flickr"></i></a>
            <a class="instagram" href="https://www.instagram.com/matteo.bovero/"
               target="_blank"><i class="bx bxl-instagram"></i></a>
            <a class="twitter" href="https://twitter.com/mobovero"
               target="_blank"><i class="bx bxl-twitter"></i></a>
        </div>
      </div>

      <nav id="navbar" class="nav-menu navbar" >
        <ul>
          <li>
            <router-link :to="{ name: 'homepage'}" class="nav-link my-0 py-1">
              <i class="bx bx-home"></i>
              <span>Home</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'personale'}" class="nav-link my-0 py-1">
              <i class="bx bx-user"></i>
              <span>Qualcosa di me</span>
            </router-link>
            <ol v-if="$route.name=='personale'" class="ms-3 my-0 py-0">
              <li><a class="nav-link my-0 py-1" href="#qualcosa"><i class='bx bxs-chevron-right bx-xs'></i>
                Qualcosa di me
              </a></li>
              <li><a class="nav-link my-0 py-1" href="#fotografia"><i class='bx bxs-chevron-right bx-xs'></i>Fotografia
              </a></li>
              <li><a class="nav-link my-0 py-1" href="#mare"><i class='bx bxs-chevron-right bx-xs'></i>Il Mare</a></li>
              <li><a class="nav-link my-0 py-1" href="#acquario"><i class='bx bxs-chevron-right bx-xs'></i>Acquariologia
              </a></li>
              <li><a class="nav-link my-0 py-1" href="#astronomia"><i class='bx bxs-chevron-right bx-xs'></i>Astronomia
              </a></li>
              <li>
                <a class="nav-link scrollto my-0 py-1" href="#saluti"><i class='bx bxs-chevron-right bx-xs'></i>
                  Saluti</a>
              </li>

            </ol>
          </li>
          <li>
            <router-link :to="{ name: 'professione'}" class="nav-link my-0 py-1">
              <i class="bx bx-server"></i>
              <span>Il mio lavoro</span>
            </router-link>
            <ol v-if="$route.name=='professione'" class="ms-3 my-0 py-0">
              <li><a class="nav-link my-0 py-1" href="#informatica"><i class='bx bxs-chevron-right bx-xs'></i>
                Informatica
              </a></li>
              <li><a class="nav-link my-0 py-1" href="#skills"><i class='bx bxs-chevron-right bx-xs'></i>Skills</a></li>
              <li><a class="nav-lin my-0 py-0" href="#resume"><i class='bx bxs-chevron-right bx-xs'></i>Resume</a></li>
              <li><a class="nav-link my-0 py-1" href="#education"><i
                  class='bx bxs-chevron-right bx-xs'></i>Education</a></li>
              <li><a class="nav-link my-0 py-1" href="#pubblication"><i class='bx bxs-chevron-right bx-xs'></i>Pubblication</a>
              </li>
            </ol>
          </li>
          <li>
            <router-link :to="{ name: 'fotografia'}" class="nav-link my-0 py-1">
              <i class="bx bx-camera"></i>
              <span>Fotografia</span>
            </router-link>

          </li>

          <li>
            <router-link :to="{ name: 'linktree'}" class="nav-link  my-0 py-1">
              <i class="bx bx-envelope"></i>
              <span>Linktree</span>
            </router-link>
          </li>
        </ul>
      </nav><!-- .nav-menu -->
    </div>
  </header><!-- End Header -->
  <main id="main" class="scrollspy-example" data-bs-offset="0" data-bs-spy="scroll" data-bs-target="#navbar"
        tabindex="0">
    <router-view></router-view>
  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>Matteo O. Bovero</span></strong>
      </div>
    </div>
  </footer><!-- End  Footer -->

  <a class="back-to-top d-flex align-items-center justify-content-center" href="#"><i
      class="bi bi-arrow-up-short"></i></a>

</template>

<script>
//
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./assets/theme_1641719176725.css";

import "./assets/vendor/bootstrap/css/bootstrap.min.css";
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets/vendor/boxicons/css/boxicons.min.css";
import "./assets/css/style.css";
import "bootstrap/dist/js/bootstrap.min.js";

export default {
  mounted() {
    document.title = "Matteo O. Bovero";
    document.body.classList.add('d-flex', 'flex-column')
  },
  data() {
    return {
      toggle: false, //toggle variable
    }
  },
  methods: {
    navbar() {
      this.toggle=!this.toggle;
      const el = document.body;
      el.classList.toggle('mobile-nav-active');
      console.log(this.toggle)
    },
      track () {
          this.$ga.page('/')
      }
  }
}
</script>
