import { createApp } from 'vue/dist/vue.esm-bundler'
import router from './router.js'
import App from './App.vue'
import VueGtag from "vue-gtag";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'


createApp(App)
    .use(router)

    .use(VueGtag, {
        pageTrackerScreenviewEnabled: true,
        config: {
            id: 'UA-7054455-1',
            params: {
                anonymize_ip: true
            }
        },
        router
    })
    .use(VueViewer)
    .mount('#app')