<template>
    <section id="photo" class="about">
        <div class="container">

            <div id="photography" class="section-title">
                <h2>Fotografia</h2>
            </div>

            <div class="row">
                <div class="col-lg-12 pt-12 pt-lg-0 content" data-aos="fade-left">
                    <p class="fst-italic">
                        Acidi, solventi, vasche di sviluppo e camere oscure. Questo è il fotografo nell'immaginario
                        collettivo.<br><br>
                        Purtroppo, quando questa era la normale prassi, io ero troppo piccolo.<br>
                        Da bambino, scattando con la reflex di famiglia, le foto venivano portate a sviluppare in laboratori esterni.<br>
                        Oggi, con il digitale, il problema non si pone. Le poche stampe di cui ho necessità le invio a laboratori esterni. Il resto rimane sotto forma di bit.
                    </p>

                </div>
            </div>
        </div>
    </section>
    <section id="gallery" class="about">
        <div class="container">
            <div id="gall" class="section-title">
                <h2>Galleria</h2>
            </div>

            <div class="row">
                <h3>Landscape</h3>
                <div class="col-lg-12 pt-12 pt-lg-0 content" data-aos="fade-left">
                    <viewer :images="landscape">
                        <img style="width:100px;height:100px;margin:2px" v-for="src in landscape" :key="src" :src="src">
                    </viewer>

                </div>
            </div>
            <br>
            <div class="row">
                <h3>Nature</h3>
                <div class="col-lg-12 pt-12 pt-lg-0 content" data-aos="fade-left">
                    <viewer :images="nature">
                        <img style="width:100px;height:100px;margin:2px" v-for="src in nature" :key="src" :src="src">
                    </viewer>

                </div>
            </div>
            <br>
            <div class="row">
                <h3>Portrait</h3>
                <div class="col-lg-12 pt-12 pt-lg-0 content" data-aos="fade-left">
                    <viewer :images="portrait">
                        <img style="width:100px;height:100px;margin:2px" v-for="src in portrait" :key="src" :src="src">
                    </viewer>

                </div>
            </div>
            <br>
            <div class="row">
                <h3>Sport</h3>
                <div class="col-lg-12 pt-12 pt-lg-0 content" data-aos="fade-left">
                    <viewer :images="sport">
                        <img style="width:100px;height:100px;margin:2px" v-for="src in sport" :key="src" :src="src">
                    </viewer>

                </div>
            </div>
            <br>
            <div class="row">
                <h3>Water</h3>
                <div class="col-lg-12 pt-12 pt-lg-0 content" data-aos="fade-left">
                    <viewer :images="water">
                        <img style="width:100px;height:100px;margin:2px" v-for="src in water" :key="src" :src="src">
                    </viewer>

                </div>
            </div>
        </div>
    </section>

</template>
<script>
export default {
    data() {
        return {
            landscape: [
                "/gallery/landscape/bovero_07.jpg",
                "/gallery/landscape/bovero_08.jpg",
                "/gallery/landscape/bovero_40.jpg",

                "/gallery/landscape/bovero_44.jpg",
                "/gallery/landscape/bovero_47.jpg",
                "/gallery/landscape/bovero_68.jpg",
                "/gallery/landscape/bovero_43.jpg",
            ],
            nature:[
                "/gallery/nature/bovero_48.jpg",
                "/gallery/nature/bovero_38.jpg",
                "/gallery/nature/bovero_51.jpg",
                "/gallery/nature/bovero_50.jpg",
                "/gallery/nature/bovero_49.jpg",
                "/gallery/nature/bovero_39.jpg",
                "/gallery/nature/bovero_41.jpg",
            ],
            portrait:[
                "/gallery/portrait/bovero_70.jpg",
                "/gallery/portrait/bovero_69.jpg",
                "/gallery/portrait/bovero_45.jpg",
                "/gallery/portrait/bovero_02.jpg",
                "/gallery/portrait/bovero_17.jpg",
                "/gallery/portrait/bovero_18.jpg",
                "/gallery/portrait/bovero_16.jpg",
                "/gallery/portrait/bovero_71.jpg"
            ],
            sport:[
                "/gallery/sport/bovero_01.jpg",
                "/gallery/sport/bovero_04.jpg",
                "/gallery/sport/bovero_09.jpg",
                "/gallery/sport/bovero_10.jpg",
                "/gallery/sport/MOB_8875_low.jpg",
                "/gallery/sport/MOB_8815_low.jpg",
                "/gallery/sport/MOB_8859_low.jpg",
                "/gallery/sport/MOB_8890_low.jpg",
                "/gallery/sport/MOB_8858_low.jpg",
                "/gallery/sport/MOB_8898_low.jpg",
                "/gallery/sport/MOB_8805_low.jpg",
                "/gallery/sport/bovero_62.jpg",
                "/gallery/sport/bovero_65.jpg",
                "/gallery/sport/bovero_59.jpg",
                "/gallery/sport/bovero_64.jpg",
                "/gallery/sport/bovero_60.jpg",
                "/gallery/sport/bovero_61.jpg",
                "/gallery/sport/bovero_63.jpg",
                "/gallery/sport/bovero_66.jpg",
                "/gallery/sport/bovero_56.jpg",
                "/gallery/sport/bovero_57.jpg",
                "/gallery/sport/bovero_58.jpg",
                "/gallery/sport/bovero_55.jpg",
                "/gallery/sport/bovero_54.jpg",
                "/gallery/sport/bovero_53.jpg",
                "/gallery/sport/bovero_52.jpg",


                "/gallery/sport/bovero_67.jpg",

                "/gallery/sport/bovero_24.jpg",
                "/gallery/sport/bovero_27.jpg",
                "/gallery/sport/bovero_11.jpg",
                "/gallery/sport/bovero_36.jpg",
                "/gallery/sport/bovero_28.jpg",
                "/gallery/sport/bovero_33.jpg",

                "/gallery/sport/bovero_15.jpg",

                "/gallery/sport/bovero_35.jpg",

                "/gallery/sport/bovero_13.jpg",


                "/gallery/sport/bovero_14.jpg",


                "/gallery/sport/bovero_37.jpg",



                "/gallery/sport/bovero_30.jpg",
                "/gallery/sport/bovero_34.jpg",

                "/gallery/sport/bovero_25.jpg",

                "/gallery/sport/bovero_31.jpg",


            ],
        water:[
            "/gallery/water/IMG_3504_1.JPG",
            "/gallery/water/IMG_3535.JPG",
            "/gallery/water/01-bovero.jpg",
            "/gallery/water/02-bovero.jpg",
            "/gallery/water/bovero_22.jpg",

            "/gallery/water/IMG_0177.jpg",
            "/gallery/water/IMG_0254.jpg",
            "/gallery/water/IMG_0250.jpg",
            "/gallery/water/IMG_0313.jpg",
            "/gallery/water/IMG_0231_1.jpg",
            "/gallery/water/IMG_0263_1.jpg",
            "/gallery/water/IMG_2130.JPG",
            "/gallery/water/IMG_0224.jpg",
            "/gallery/water/IMG_0189.jpg",
            "/gallery/water/IMG_2711.JPG",

            "/gallery/water/IMG_0280_1.JPG",

            "/gallery/water/IMG_0500.jpg",
            "/gallery/water/IMG_0190.jpg",
            "/gallery/water/IMG_0187.jpg",
            "/gallery/water/IMG_2770.JPG",
            "/gallery/water/IMG_0406.jpg",
            "/gallery/water/IMG_0299.jpg",
            "/gallery/water/IMG_0181.jpg",
            "/gallery/water/IMG_0237.jpg",
            "/gallery/water/IMG_0274_1.jpg",
            "/gallery/water/IMG_0523.jpg",
            "/gallery/water/IMG_0240.jpg",
            "/gallery/water/IMG_0194.jpg",
            "/gallery/water/IMG_0188.jpg",
            "/gallery/water/IMG_0195.jpg",
            "/gallery/water/IMG_0267.jpg",

            "/gallery/water/IMG_0514.jpg",
            "/gallery/water/IMG_0230_1.jpg",
            "/gallery/water/IMG_0243_1.jpg",
            "/gallery/water/IMG_0302.jpg",
            "/gallery/water/IMG_0273.jpg",
            "/gallery/water/IMG_0499.jpg",

            "/gallery/water/IMG_0504.jpg",
            "/gallery/water/IMG_2126.JPG",
            "/gallery/water/IMG_0186.jpg",
            "/gallery/water/IMG_0512.jpg",
            "/gallery/water/IMG_0290_2.JPG",
            "/gallery/water/IMG_0271.jpg",

        ]
        };
    },
    methods: {
        show() {
            this.$viewerApi({
                images: this.images,
            })
        },
    },
}
</script>