<template>
  <section id="about" class="about">
    <div class="container">

      <div id="qualcosa" class="section-title">
        <h2>Qualcosa su di me</h2>

      </div>

      <div class="row">
        <div class="col-lg-4" data-aos="fade-right">
          <img alt="" class="img-fluid" src="@/assets/img/profile-img.jpg">
        </div>
        <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
          <p class="fst-italic">
            Mi chiamo Matteo e sono nato a Torino, città dove risiedo da sempre, nel 1978.<br><br>

            Ho frequentato le scuole medie presso le "Protette di San Giuseppe" e ho ottenuto il titolo di Perito in
            elettrotecnica ed automazione all'istituto industriale "Leonardo Murialdo". Completa il mio percorso di
            studi la Laurea in Ingegneria informatica al Politecnico di Torino.<br><br>

            L'informatica è il perno della mia vita lavorativa, ma non solo: è anche un interesse sempre vivo, che
            riempie parte del mio tempo libero.<br>
            Quando non sono di fronte a un PC amo scrutare il cielo di notte, muovermi nelle profondità del mare e
            solcare le acque spinto dal vento. Viaggiare con la mente in mondi di fantasia e occuparmi di acquari e
            bonsai. Mi intrigano la filosofia e le arti orientali, e quella forma poetica così minimalista ed
            espressiva che è l'haiku.<br>
            Soprattutto amo la fotografia: la possibilità di cogliere un momento dello scorrere del tempo, perchè
            carico di significato o di emozioni, o perchè esteticamente perfetto. E di fermarlo in un click.
          </p>

        </div>
      </div>

      <div id="fotografia" class="section-title mt-5">
        <h2>Fotografia</h2>
        <p>La mia passione per la fotografia, nasce quando, da bambino, correvo su e giù per le Dolomiti con la
          macchina fotografica di famiglia, una Canon Reflex completamente manuale (ad esclusione dell'esposimetro
          già incorporato). Per anni scattammo con il 35mm in dotazione. Quando passammo 70-200 fu tutta un'altra
          esperienza!<br><br>

          Purtroppo, il costo dello sviluppo fotografico (sempre di negativi e fotografie, mai diapositive), mi
          spinse ad abbandonare questo magnifico hobby.<br><br>

          L'avvento del digitale riaccese in me la voglia di scattare e così comprai una compatta, la Casio Exilim
          EX-Z70. Non ho mai rimpianto l'acquisto di questa macchina, in quanto è sicuramente un'ottima compagna di
          viaggio, viste le sue ridottissime dimensioni. Purtroppo però, le sue capacità sono molto limitate,
          soprattutto perchè lavora completamente in automatico. Così decisi di acquistare la mia prima reflex
          digitale, una Nikon D80. Acquistai la macchina in kit, con l'obbiettivo Nikon 18-135. Sicuramente
          l'obbiettivo non è qualitativamente il top di gamma, ma vista la spesa contenuta, ne è valsa sicuramente
          la pena. Dopo poco, decisi di espandere il mio kit fotografico, quindi acquistai: un obbiettivo tele, il
          Nikon 70-300, per il quale vale il discorso del precendente, un flash il Nikon SB-600, sicuramente un
          ottimo acquisto e un Nikkor 50mm, un obbiettivo magnifico.<br><br>

          Da poco, dopo una lunga attesa, sono il felicissimo possessore di una Nikon D800 e un 27-70 e 70-200
          entrambi f/2.8.</p>
      </div>
      <div id="mare" class="section-title mt-5">
        <h2>Il Mare</h2>
        <p>Chiudi gli occhi. Immagina di fluttuare libero, senza peso, attorno a te il silenzio, un silenzio
          ovattato, rilassante. Poi apri gli occhi e davanti al tuo sguardo stupito si schiude un universo di pesci
          e molluschi di ogni genere. E tu fai parte di quell'ambiente.<br><br>

          Questo è per me l'immersione. Entrare in punta dei piedi in un mondo che non mi appartiene, con il
          rispetto referenziale di chi è consapevole di osservare qualcosa di grandioso, un'equilibrio secolare a
          cui ho, per qualche fortuita coincidenza, avuto accesso.<br><br>

          Ho iniziato la mia avventura al di sotto della superficie del mare, facendo snorkeling, esplorando gli
          anfratti scogliosi vicini alle spiagge. Il passo all'uso delle bombole è stato veramente breve. Ho
          seguito i corsi di sub presso la "Compagnia dell'Avventura", di Arzachena (Olbia-Tempio), che mi ha
          permesso di scoprire questo fantastico mondo.<br><br>

          Il "Mare Nostrum" offre momenti di relax non solo vagando nel suo freddo abbraccio, ma anche solcando i
          flutti, trasportati dal vento e cullati dallo sciabordio delle onde. Poco prima di avventurarmi nelle
          profondità marine, ho scoperto l'amore per la navigazione a vela. Per svariati anni ho trascorso le mie
          vacanze a bordo di natanti con la piacevole compagnia dei miei amici, passando da barche più "calme e
          tranquille", diciamo da crociera, come i Bavaria 40 a vascelli più nervosi, scattanti e performanti come
          il First 31 con timone a barra. Grandi avventure in un piccolo mare.<br><br>

          Dopo aver provato tutto questo, non posso che condividere il motto della Compagnia dell'Avventura:<br><br>

          <span style="width:100%"> <b><i>"sott'acqua, per mare, nel vento".</i></b></span>
        </p>
      </div>
      <div id="acquario" class="section-title mt-5">
        <h2>Acquariologia</h2>
        <p>Da diversi anni mi dedico agli acquari. Il mio interesse è rivolto non solo all'acquacoltura ma anche alla
          cura di cultivar adatte alla vita completamente sommersa, per la creazione di un vero e proprio ecosistema
          (quasi) autosufficiente. In quest'ottica pesci, piante e arredamenti (quali rocce e tronchi contorti) hanno
          ugual peso e valenza per l'impatto visivo ed emotivo. Per questo aspetto, fonte di ispirazione è Takashi
          Amano, massimo esperto di acquariofilia, capace di creare in acqua dolce paesaggi che riproducono giardini
          zen.<br><br>

          Prendersi cura del microcosmo sommerso significa per me andare contro la logica frenetica della vita
          contemporanea, e rallentare. Trovare un tempo che distende e rilassa sia mentre si crea, sia mentre si
          osserva.<br><br>

          Possiedo due vasche. La principale ha un vetro panoramico, per 50l di capienza. In questa ospito una gran
          varietà di piante accompagnate da alcune specie di pesci tipici dell'acquario casalingo, quali i Neon. La
          seconda vasca, più piccola, 30l, è nata per la sperimentazione. In questo momento è priva di fauna e la flora
          affonda le proprie radici in un composto di terriccio per vasi e sabbia di quarzo, che ricopre anche il
          terriccio. In futuro, quando i tannini rilasciati dal terreno si saranno notevolmente ridotti, ospiterà una
          colonia di Neo Caridina Red Cherry.
        </p>
      </div>
      <div id="astronomia" class="section-title mt-5">
        <h2>Astronomia</h2>
        <p>Mi sono avvicinato all'astronomia per il fascino che la volta celeste esercita su di me. Trovo infatti che
          osservare il cielo sia fonte di continuo stupore.<br><br>

          Recentemente il desiderio di approfondire le mie conoscenze e di condividerle mi ha portato a partecipare
          attivamente alla vita di un'associazione culturale di astrofili. Oltre alla divulgazione delle scienze
          astronomiche, l'associazione gestisce un planetario digitale e un telescopio rifrattore Ritchey-Chretien del
          diametro di 60 cm. Sono stato, per alcuni anni, responsabile di tali infrastrutture informatiche.<br><br>

          Ho sperimentato quindi sia la possibilità di scrutare i corpi celesti con uno strumento professionale, sia il
          divertimento di unire due passioni e di dedicarmi all'astrofotografia.
        </p>
      </div>
      <div id="saluti" class="section-title mt-5">
        <h2>Saluti</h2>

        <div class="row py-1">
          <div class="col-md-12 mb-4">
            <div class="card  border-0 h-100">
              <div class="card-body d-flex flex-column align-items-start text-end">
                <p class="card-text  w-100">
                  <b>"Seconda stella a destra<br>
                    e poi dritto fino al mattino"<br></b>
                  <i>Peter Pan, Peter Pan, J.M. Barrie, 1904<br>
                    L'isola che non c'è, Edoardo Bennato, 1980<br>
                    James T. Kirk, Star Trek VI Rotta verso l'ignoto, 1991</i><br><br>


                  <b>"Siamo un popolo lento all'ira, ma una volta provocati siamo<br>
                    come i draghi: la nostra collera non conosce limiti."<br></b>
                  <i>regina Islanzadi, Brisingr, Christopher Paolini, 2008<br></i>
                </p>
                <p class="card-text  w-100 text-start pt-5">
                  Grazie a te visitatore che sei arrivato in questo spazio digitale.<br><br>

                  Tra una stella, un click, un tuffo nel profondo blu e un volo in sella ad una creatura fantastica,
                  affronto le vicissitudini di ogni giorno, in un viaggio incredibile accompagnato dalla mia famiglia e
                  dagli amici più cari.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>
  </section>

</template>