import { createWebHistory, createRouter } from 'vue-router'

import Homepage from './pages/home'
import Personale from './pages/personale'
import Professione from './pages/professione'
import Fotografia  from './pages/fotografia'
import Linktree  from './pages/linktree'

// Routes
const routes = [
    {
        path: '/',
        name: 'homepage',
        component: Homepage,
        meta: {
            nav: [
                { name: 'Home' }
            ]
        }
    },
    {
        path: '/personale',
        name: 'personale',
        component: Personale,
        meta: {
            nav: [
                { name: 'Personale' }
            ]
        }
    },
    {
        path: '/professione',
        name: 'professione',
        component: Professione,
        meta: {
            nav: [
                { name: 'professione' }
            ]
        }
    },
    {
        path: '/fotografia',
        name: 'fotografia',
        component: Fotografia,
        meta: {
            nav: [
                { name: 'fotografia' }
            ]
        }
    },
    {
        path: '/linktree',
        name: 'linktree',
        component: Linktree,
        meta: {
            nav: [
                { name: 'linktree' }
            ]
        }
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: '/'
    }

]

// Router instance
const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;
