<template>

  <section id="facts" class="facts">
    <div class="container">

      <div class="section-title"  id="informatica">
        <h2>L'informatica</h2>
        <p>Laureato in ingegneria informatica, appassionato della materia nella vita, ho approfondito molti settori
          di questo ampio campo, dal web engineerig alla programmazione per mobile, dal consulting b2b al supporto
          al cliente.<br><br>

          Più in basso è possibile prendere visione del mio resume e delle mie attività nei principali campi
          in cui mi sono applicato.</p>
      </div>

      <div class="row no-gutters">

        <div class="col-lg-6 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up">
          <div class="count-box">
            <i class="bi bi-phone"></i>
            <span class="purecounter" data-purecounter-duration="1" data-purecounter-end="232"
                  data-purecounter-start="0"></span>
            <p><strong>Mobile</strong><br> Mi sono avvicinato allo sviluppo per device mobili nel 2000, con l'acquisto
              di un Palm Vx della Palm
              Computing, allora divisione della 3Com.<br><br>

              Ancora studente, ho iniziato a collaborare con la Tower Technologies, per la realizzazione di
              "CardSalesMan", un'applicativo sviluppato in ambiente PalmOS per la gestione delle vendite. Parte del
              "Progetto ArcheoMap", sviluppato come tesi di laurea, è consistito nella realizzazione di un'applicazione
              per PalmOS per la raccolta e la consultazione di dati archeologici in un ambiente distribuito.<br><br>

              La tecnologia mobile è, oggi più che mai, in continua evoluzione, evoluzione chi mi ha portato ad
              appassionarmi al sistema operativo Android, sviluppato da Google. In questo campo, per esempio, ho
              seguito lo sviluppo per Tower Technologies di applicazioni quali Bottega Verde Live.</p>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="100">
          <div class="count-box">
            <i class="bi bi-journal-richtext"></i>
            <span class="purecounter" data-purecounter-duration="1" data-purecounter-end="521"
                  data-purecounter-start="0"></span>
            <p><strong>Web engineering</strong><br> Come molti web developer, le prime esperienze di realizzazione
              pagine furono relative alla creazione di
              codice statico, nell'allora nuovissino standard HTML 4.0. Nel corso tempo, per lavoro e per piacere, ho
              acquisito conoscenze di nuove tecnologie, quali i CSS o l'XHTML e nuovi linguaggi nati specificatamente
              per il web quali il PHP per il codice server side o il Javascript per il lato client.<br><br>

              Oggi, nella realizzazioni di web site, sfrutto CMS quali Joomla o strumenti come lo Zend Framework,
              Laravel,
              Symfony, JQuery e Vue.<br><br>

              Come system administrator prediligo ambienti Linux, Debian in particolare, con webserver Apache e
              database quali PostgreSQL o MySQL.</p>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="200">
          <div class="count-box">
            <i class="bi bi-person-check"></i>
            <span class="purecounter" data-purecounter-duration="1" data-purecounter-end="1453"
                  data-purecounter-start="0"></span>
            <p><strong>Consulting</strong> <br>Portato per natura all'ottimizzazione dei processi, ho perfezionato le
              mie inclinazioni naturali grazie a
              percorsi formativi quali la "programmazione lineare" al Politecnico di Torino.<br><br>

              Mi sono occupato dell'assitenza al cliente e della sua formazione. Ho studiato le necessità del
              personale analizzando in loco le necessitÃ per la creazione di nuove soluzioni e il miglioramento di
              pacchetti già in produzione.<br><br>

              In una sola frase, mi sono occupato di problem solving.</p>
          </div>
        </div>

      </div>

    </div>
  </section>
  <section id="skills" class="services" >
    <div class="container" >

      <div class="section-title" >
        <h2>Teckical Skills</h2>
        <p></p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
          <div class="icon"><i class="bi bi-briefcase"></i></div>
          <h4 class="title"><a href="">Programming language</a></h4>
          <p class="description">PHP, Java, C, Perl, Python</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
          <div class="icon"><i class="bi bi-wifi"></i></div>
          <h4 class="title"><a href="">Web programming</a></h4>
          <p class="description">Symfony, Laravel, ZendFramework/2, HTML, XML, CSS, Javascript, JQuery, Vue,
            SASS,
            Node.JS</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
          <div class="icon"><i class="bi bi-calendar4-week"></i></div>
          <h4 class="title"><a href="">Database</a></h4>
          <p class="description">MySQL, PostgreSQL</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
          <div class="icon"><i class="bi bi-binoculars"></i></div>
          <h4 class="title"><a href="">Scripting programming</a></h4>
          <p class="description">bash, perl, python</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
          <div class="icon"><i class="bi bi-phone"></i></div>
          <h4 class="title"><a href="">Mobile programming</a></h4>
          <p class="description">Palm OS/4, Android/3</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
          <div class="icon"><i class="bi bi-hdd-network"></i></div>
          <h4 class="title"><a href="">System administrator</a></h4>
          <p class="description">Debian, Amazon Web Services, Microsoft Outlook Web Application, Windows
            Server</p>
        </div>

        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
          <div class="icon"><i class="bi bi-diagram-3"></i></div>
          <h4 class="title"><a href="">Modeling language</a></h4>
          <p class="description">UML, Entity Relationship</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
          <div class="icon"><i class="bi bi-card-checklist"></i></div>
          <h4 class="title"><a href="">System development methodology</a></h4>
          <p class="description">Object-oriented Design (OOD), Agile Development (Scrum)</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
          <div class="icon"><i class="bi bi-brightness-high"></i></div>
          <h4 class="title"><a href="">Tools & platform</a></h4>
          <p class="description">Soap, Json, LaTeX, Joomla</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
          <div class="icon"><i class="bi bi-gear"></i></div>
          <h4 class="title"><a href="">Hardware tool</a></h4>
          <p class="description">PIC16F84, Arduino, RaspberryPi, ESP32</p>
        </div>
      </div>
    </div>
  </section><!-- End Services Section -->
  <section id="resume" class="resume">
  <div class="container">

    <div class="section-title">
      <h2>Resume</h2>
      <p></p>
    </div>
    <div class="row">
      <div class="col-lg-6" data-aos="fade-up">
        <h3 class="resume-title">Professional Experience</h3>
        <div class="resume-item">
          <h4>Senior Software Engineer</h4>
          <h5>September 2020 - Today</h5>
          <p><em>Fluentify, Torino </em></p>
          <p></p>
        </div>
        <div class="resume-item">
          <h4>IT Manager</h4>
          <h5>December 2017 - September 2020</h5>
          <p><em>Consulman srl, Torino </em></p>
          <p>Project manager for software development, responsible for IT department.</p>
        </div>
        <div class="resume-item">
          <h4>Senior Developer and Project Manager</h4>
          <h5>April 2013 - December 2017</h5>
          <p><em>Atlante Informatica srl, Moncalieri (TO) </em></p>
          <p>Project manager and web solution developer for customer CRM and internal CRM project.</p>
        </div>
        <div class="resume-item">
          <h4>Account Manager</h4>
          <h5>January 2013 - April 2013</h5>
          <p><em>NoemaLife srl, Bologna </em></p>
          <p>Customer Care, introduction of new products, user training, analysis of requirement for new products and
            customization for just in use software in hospital setting.</p>
        </div>
        <div class="resume-item">
          <h4>Account Manager</h4>
          <h5>May 2011 - December 2012</h5>
          <p><em>Prolink sas, Moncalieri </em></p>
          <p>Customer Care, introduction of new products, user training, analysis of requirement for new products and
            customization for just in use software in hospital setting.</p>
        </div>
        <div class="resume-item">
          <h4>Mobile Developer</h4>
          <h5>May 2011 - December 2012</h5>
          <p><em>Tower Technologies, Torino </em></p>
          <p>Software developer for Android 3.0. Final client “Bottega Verde”</p>
        </div>
        <div class="resume-item">
          <h4>Web Engineering</h4>
          <h5>July 2008 - April 2011</h5>
          <p><em>Freelance, Torino </em></p>
          <p>CMS installation, customization and optimization</p>
        </div>
        <div class="resume-item">
          <h4>Integration Developer</h4>
          <h5>June 2001 - June 2008</h5>
          <p><em>Contur srl, Torino </em></p>
          <p>Software integration developer (source data: Sabre Inc. to internal web toolkit) and system
            administrator (Debian)</p>
        </div>
      </div>
      <div class="col-lg-6" data-aos="fade-up" id="education">
        <h3 class="resume-title">Education</h3>
        <div class="resume-item">
          <h4>Abilitazione all'esercizio della professione di ingegneria</h4>
          <h5>2010</h5>
          <p><em>Politecnico di Torino </em></p>
          <p></p>
        </div>
        <div class="resume-item">
          <h4>Master's degree, InformaticsMaster's degree, Informatics</h4>
          <h5>1999 - 2010</h5>
          <p><em>Politecnico di Torino </em></p>
          <p></p>
        </div>
        <div class="resume-item">
          <h4>Certificazione PET with PASS</h4>
          <h5>2004</h5>
          <p><em>University of Cambridge </em></p>
          <p></p>
        </div>
        <div class="resume-item">
          <h4>Cisco systems – Cisco Network Academy</h4>
          <h5>2001 - 2002</h5>
          <p><em>Politecnico di Torino </em></p>
          <p>semesters 1, 2, 3 e 4</p>
        </div>
        <div class="resume-item">
          <h4>Perito, elettronica ed automazione</h4>
          <h5>1992 - 1997</h5>
          <p><em>Istituto Tecnico Industriale Leonardo Murialdo (Torino) </em></p>
          <p></p>
        </div>
        <div class="resume-item">
          <h4>Studio del linguaggio di programmazione Assembly per il processore 8086</h4>
          <h5>1994 - 1995</h5>
          <p><em>Istituto Tecnico Industriale Leonardo Murialdo (Torino)</em></p>
          <p></p>
        </div>
      </div>
      <div class="col-lg-6" data-aos="fade-up" id="pubblication">
        <h3 class="resume-title">Pubblication</h3>
        <div class="resume-item">
          <h4>ArcheoFoss - Open Source, Free Software e Open Format neo processi di ricerca archeologica. </h4>
          <h5>2011</h5>
          <p><em>casa
            editrice Edilpuglia, Bari </em></p>
          <p>Atti del 5° Workshop (Foggia, 5-6 maggio 2010) a cura di Giuliano De Felice e Maria Giuseppina Sibilano </p>
        </div>
        <div class="resume-item">
          <h4>Archeomap: Progetto di tecnologie informatiche per la realizzazione di un Sistema Informativo
            finalizzato
            alla gestione di scavi archeologici</h4>
          <h5>2010</h5>
          <p><em>Politecnico di Torino</em></p>
          <p>tesi di laurea aziendale, sviluppata con l'ausilio di PHP, PostgreSQL e C (per PalmOS)</p>
        </div>
        <div class="resume-item">
          <h4>Protocolli di routing: EIGRP</h4>
          <h5></h5>
          <p><em>Politecnico di Torino</em></p>
          <p>Tesina sperimentale volta a testare il funzionamento dell'eigrp e la sua tolleranza ai guasti.</p>
        </div>
        <div class="resume-item">
          <h4>Studio del codice di Goppa (23, 13, 5)</h4>
          <h5></h5>
          <p><em>Politecnico di Torino </em></p>
          <p>Tesina teorico sperimentale volta ad analizzare il
            codice di GOPPA (23, 13, 5) ed a implementare il suddetto algoritmo (linguaggio utilizzato: C)</p>
        </div>
        <div class="resume-item">
          <h4>Wardriving e network auditing</h4>
          <h5></h5>
          <p><em>Tesina sperimentale per la verifica della robustezza del
            protocollo WEP e la possibilità di rottura della chiave con l'utilizzo di disposiviti palmari (Compaq
            H3600).</em></p>
          <p></p>
        </div>
        <div class="resume-item">
          <h4>Bonobo: l'intefaccia Corba di Gnome</h4>
          <h5></h5>
          <p><em>Istituto Tecnico Industriale Leonardo Murialdo (Torino)</em></p>
          <p>Tesina esplorativa volta a valutare la compatibilità
            dell'implementazione corba di Gnome.</p>
        </div>
      </div>
    </div>
  </div>
  </section>




</template>