<template>
        <div class="card border-0 h-100 text-center">
          <div class="card-body d-flex flex-column align-items-start">
            <h4 class="card-title  text-center w-100">
              LINK TREE
            </h4>
            <div class="card-text text-center w-100" >
              <h3>Matteo O. Bovero</h3>
              <p>
                  <i>Software Engineer, Developer, Photographer, Scuba Diver</i><br>
                  @Torino, Italy
              </p>
            </div>
            <p class="card-text text-center w-100">
              <a class="links btn btn-outline-dark btn-block" href="https://www.linkedin.com/in/mobovero/"
                 target="_blank"><i class="fab fa-linkedin">&nbsp;</i>Linkedin</a><br>
              <a class="links btn btn-outline-dark btn-block" href="https://github.com/matte78" target="_blank"><i
                  class="fab fa-github">&nbsp;</i>GitHub</a><br>
              <a class="links btn btn-outline-dark btn-block" href="https://www.flickr.com/photos/bovero/"
                 target="_blank"><i class="fab fa-flickr">&nbsp;</i>Flickr</a><br>
              <a class="links btn btn-outline-dark btn-block" href="https://www.instagram.com/matteo.bovero/"
                 target="_blank"><i class="fab fa-instagram">&nbsp;</i>Instagram
              </a><br>
              <a class="links btn btn-outline-dark btn-block" href="https://twitter.com/mobovero"
                 target="_blank"><i class="fab fa-twitter">&nbsp;</i>Twitter</a>
            </p>
          </div>
        </div>

</template>

<style>
.links {
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid white;
  border-width: 2px;
  width: 290px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 40px;
}

a {
  text-decoration: none;
  color: white;
  transition: color 1s;
}
</style>